import { apiService } from "../api.service";

// Requests
import { DeclineReasonRequest } from "../../models/api/requests";

// Responses
import {
  ServiceRequestsResponse,
  ServiceResponse,
  MessageResponse
} from "../../models/api/responses";

/**
 * Fetch service-requests Request
 * @return - ServiceRequestsResponse || ErrorResponse
 */
export const fetchServiceRequestsRequest = async () => {
  const response = await apiService.get("/services/requests");
  if (response.hasError) {
    return response;
  }
  return new ServiceRequestsResponse(response);
};

/**
 * Approve service-requests Request
 * @return - ServiceRequestsResponse || ErrorResponse
 */
export const approveServiceRequest = async serviceId => {
  const response = await apiService.post(
    `/services/requests/${serviceId}/accept`
  );
  if (response.hasError) {
    return response;
  }
  return new MessageResponse(response);
};

/**
 * Fetch service  Request
 * @return - ServiceResponse || ErrorResponse
 */
export const fetchServiceDetailsRequest = async serviceId => {
  const response = await apiService.get(`/services/${serviceId}`);
  if (response.hasError) {
    return response;
  }
  return new ServiceResponse(response);
};

/**
 * Update service-request  Request
 * @return - ServiceResponse || ErrorResponse
 */
export const updateServiceRequest = async (serviceId, payload) => {
  const response = await apiService.patch(
    `/services/requests/${serviceId}`,
    payload
  );
  if (response.hasError) {
    return response;
  }
  return new ServiceResponse(response);
};

/**
 * Decline service-requests Request
 * @return - MessageResponse || ErrorResponse
 */
export const declineServiceRequest = async (
  serviceId,
  declineReasonFormValues
) => {
  const payload = new DeclineReasonRequest(declineReasonFormValues);
  const response = await apiService.post(
    `/services/requests/${serviceId}/decline`,
    payload
  );
  if (response.hasError) {
    return response;
  }
  return new MessageResponse(response);
};
