import { useCallback, useState, useEffect } from "react";
import { geocodeByPlaceId, getLatLng } from "react-places-autocomplete";
import { useSelector } from "react-redux";

// Components
import {
  Body,
  Footer,
  TextField,
  DragAndDropUpload,
  Button,
  Select,
  ServiceTypeFormCard,
  GooglePlacesAutoComplete,
  NumberFormatterInput
} from "../../../../components/UI";
import { Label, Paragraph } from "../../../../components/typography";
import Row from "../../../../components/lists/Row";
import AssignedManagerItem from "../../../../components/lists/manageBranches/AssignedManager.item";

// Builders
import * as buttonDesignType from "../../../../builders/buttonDesign.types";

// Form Models
import {
  BranchFormModel,
  BranchServiceTypeFormModel
} from "../../../../models/app/forms";

// Calls
import { fetchManagersRequest } from "../../../../api/calls/manager.call";

// Styles
import { BranchFormStyles } from "./branchForm.styles";
import { isEmpty } from "../../../../utils";

const BranchForm = ({
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  setValues,
  handleChange,
  handleBlur,
  submitText
}) => {
  const [managersOptions, setManagersOptions] = useState([]);
  const { systemComponents } = useSelector(state => state.app);

  const fetchManagers = async () => {
    const response = await fetchManagersRequest();
    if (response.hasError) {
    }
    setManagersOptions(response.managers);
  };

  /**
   * Fetch Managers
   */
  useEffect(() => {
    fetchManagers();
  }, []);

  /**
   * Render managers from managers options
   * @returns [Managers]
   */
  const managersFilteredOptions = () => {
    return managersOptions.filter(
      option => !values.managers.includes(option.id)
    );
  };

  /**
   * Render managers from managers options
   * @returns [Managers]
   */
  const chosenManagers = () => {
    return managersOptions.filter(option =>
      values.managers.includes(option.id)
    );
  };

  /**
   * Set mobility of branch service type and add branch service type if doesnt exists
   * Note: Inside branchServiceType IF block don't change order of conditions it will break UI behaviour
   * @param serviceType - The value it checks
   * @param mobility - 'shop' || 'mobile'
   * @returns void
   */
  const handleCheckBoxChange = useCallback(
    (serviceType, mobility) => {
      let branchServiceType = values.branchServiceTypes.find(
        branchServiceType => branchServiceType.id === serviceType.id
      );

      if (branchServiceType) {
        // Must be first condition
        if (branchServiceType.mobility === "all") {
          if (mobility === "shop") {
            setFieldValue(
              "branchServiceTypes",
              values.branchServiceTypes.map(type =>
                type.id === branchServiceType.id
                  ? new BranchServiceTypeFormModel({
                      ...branchServiceType,
                      mobility: "mobile"
                    })
                  : type
              )
            );
            return;
          }

          setFieldValue(
            "branchServiceTypes",
            values.branchServiceTypes.map(type =>
              type.id === branchServiceType.id
                ? new BranchServiceTypeFormModel({
                    ...branchServiceType,
                    mobility: "shop"
                  })
                : type
            )
          );

          return;
        }

        // Must be second condition
        if (mobility !== branchServiceType.mobility) {
          setFieldValue(
            "branchServiceTypes",
            values.branchServiceTypes.map(type =>
              type.id === branchServiceType.id
                ? new BranchServiceTypeFormModel({
                    ...branchServiceType,
                    mobility: "all"
                  })
                : type
            )
          );
          return;
        }

        setFieldValue(
          "branchServiceTypes",
          values.branchServiceTypes.filter(
            type => type.id !== branchServiceType.id
          )
        );
        return;
      }

      branchServiceType = new BranchServiceTypeFormModel({
        id: serviceType.id,
        capacity: 1,
        mobility: mobility
      });

      setFieldValue("branchServiceTypes", [
        ...values.branchServiceTypes,
        branchServiceType
      ]);
    },
    [values.branchServiceTypes, setFieldValue]
  );

  /**
   * Set capacity of branch service type
   * @param e - input element
   * @param id
   * @returns void
   */
  const handleCapactyChange = useCallback(
    (e, id) => {
      const handleNumbersChangeOnly = previousValue => {
        let inputValue = e.target.validity.valid
          ? parseInt(e.target.value, 0)
          : previousValue;
        if (isNaN(inputValue)) {
          inputValue = "";
        }

        return inputValue;
      };

      setFieldValue(
        "branchServiceTypes",
        values.branchServiceTypes.map(serviceType =>
          serviceType.id === id
            ? new BranchServiceTypeFormModel({
                ...serviceType,
                capacity: handleNumbersChangeOnly(serviceType.capacity)
              })
            : serviceType
        )
      );
    },
    [values.branchServiceTypes, setFieldValue]
  );

  /**
   * Address change
   * @param address - string
   * @returns void
   */
  const handleAddressChange = address => {
    setFieldValue("address", address);
  };

  /**
   * Prefill location fields based on selected address from drop down
   * @param address - string
   * @returns void
   */
  const prefillFieldsBasedOnAddress = async (chosenAddress, placeId) => {
    const [place] = await geocodeByPlaceId(placeId);
    const latLng = await getLatLng(place);

    const { long_name: postalCode = "" } =
      place.address_components.find(c => c.types.includes("postal_code")) || {};
    const { long_name: state = "" } =
      place.address_components.find(c => c.types.includes("country")) || {};
    const { long_name: city = "" } =
      place.address_components.find(c => c.types.includes("locality")) || {};
    const address = place.formatted_address;
    const longitude = latLng.lng;
    const latitude = latLng.lat;

    setValues(
      new BranchFormModel({
        ...values,
        city,
        state,
        address,
        latitude,
        longitude,
        postalCode
      })
    );
  };

  return (
    <BranchFormStyles>
      <Body className="body">
        <div className="inputsContainer">
          <div className="md">
            <Label className="label">Branch Name</Label>
            <TextField
              className="input"
              name="name"
              value={values.name}
              touched={touched.name}
              error={errors.name}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Enter Branch Name"
            />
          </div>
          <div className="md">
            <Label className="label">Branch Address</Label>
            <GooglePlacesAutoComplete
              value={values.address}
              touched={touched.address}
              error={errors.address}
              onBlur={handleBlur}
              handleChange={handleAddressChange}
              handleSelect={prefillFieldsBasedOnAddress}
              name="address"
              placeholder="Enter Branch Address"
              className="input"
            />
          </div>
        </div>
        <div className="inputsContainer">
          <div className="md inputsContainer">
            <div className="md">
              <Label className="label">Branch State</Label>
              <TextField
                className="input"
                name="state"
                value={values.state}
                touched={touched.state}
                error={errors.state}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Enter Branch State"
              />
            </div>
            <div className="md">
              <Label className="label">Branch City</Label>
              <TextField
                className="input"
                name="city"
                value={values.city}
                touched={touched.city}
                error={errors.city}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Enter Branch City"
              />
            </div>
          </div>
          <div className="md inputsContainer">
            <div className="md">
              <Label className="label">Branch Postal/ZIP Code</Label>
              <TextField
                className="input"
                name="postalCode"
                value={values.postalCode}
                touched={touched.postalCode}
                error={errors.postalCode}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Enter Branch Code"
              />
            </div>
            <div className="md">
              <Label className="label">Branch Contact Number</Label>
              <NumberFormatterInput
                value={values.contactNumber}
                error={errors.contactNumber}
                touched={touched.contactNumber}
                onBlur={setFieldTouched}
                onChange={setFieldValue}
                className="input"
                name="contactNumber"
                placeholder="Enter a Branch Contact Number"
                format="###-###-####"
              />
            </div>
          </div>
        </div>
      </Body>
      <Body className="body">
        <div className="serviceTypesLabelContainer">
          <Label className="label">Branch Service Type</Label>
          <p className="errorMessageServiceType">
            {touched.branchServiceTypes &&
              errors.branchServiceTypes &&
              errors.branchServiceTypes}
          </p>
        </div>

        <div className="serviceTypesContainer">
          {systemComponents?.serviceTypes?.map(serviceType => (
            <ServiceTypeFormCard
              key={serviceType.id}
              serviceType={serviceType}
              value={values.branchServiceTypes.find(
                type => type.id === serviceType.id
              )}
              handleCheckBoxChange={handleCheckBoxChange}
              handleCapactyChange={handleCapactyChange}
            />
          ))}
        </div>
      </Body>
      <Body className="body">
        <div className="md">
          <Label className="label">Assign a Manager</Label>
          <Select
            value={null}
            options={managersFilteredOptions()}
            onChange={option =>
              setFieldValue("managers", [...values.managers, option[0].id])
            }
            valueKey="id"
            labelKey="fullName"
            touched={touched.branchState}
            error={errors.branchState}
            className="input"
            placeholder="Select a manager"
            alwayClearInput
            searchable
          />
        </div>
        {!isEmpty(chosenManagers()) && (
          <div>
            <Label className="label">Assigned Managers</Label>
            <div style={{ marginTop: 5 }}>
              {chosenManagers().map((manager, index) => (
                <Row key={index}>
                  <AssignedManagerItem
                    {...manager}
                    removeItem={() =>
                      setFieldValue(
                        "managers",
                        values.managers.filter((m, i) => i !== index)
                      )
                    }
                  />
                </Row>
              ))}
            </div>
          </div>
        )}
      </Body>
      <Body className="body">
        <Label>Add Branch Pictures</Label>
        <Paragraph className="dragAndDropText">
          Please browse for images or drag & drop them. Images must be minimum
          1080 pixels wide. Max 3MB in size. Must be type: jpg, jpeg, png or
          gif.
        </Paragraph>
        <DragAndDropUpload
          images={values.images}
          name="images"
          handleChange={setFieldValue}
        />
      </Body>
      <Footer>
        <div className="buttonContainer">
          <Button type="submit" buttonDesignType={buttonDesignType.PRIMARY}>
            {submitText}
          </Button>
        </div>
      </Footer>
    </BranchFormStyles>
  );
};

export default BranchForm;
