import styled from "styled-components";

export const ChatInputStyles = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 24px;

  .avatar {
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .chatInputContainer {
    width: 100%;
    position: relative;

    .emojisContainer {
      position: absolute;
      left: 8px;
      top: 8px;
      cursor: pointer;
    }

    .chatTextInput {
      width: 100%;
      height: 34px;
      background: #ffffff;
      border: 1px solid #d5d5dc;
      border-radius: 3px;
      padding: ${({ isPresetOpen }) =>
        isPresetOpen ? "0 20px" : "0 40px 0 30px"};
      display: flex;
      align-items: center;
      outline: none;
    }

    .messageContainer {
      position: absolute;
      right: 8px;
      top: 8px;
      cursor: pointer;
    }
  }

  @media only screen and (max-width: 380px) {
    .emoji-mart {
      width: 250px !important;
    }
  }
`;
