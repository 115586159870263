import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import swal from "sweetalert";
import { toast } from "react-toastify";

// Components
import { PageHeader } from "../components/PageSpecificComponents";
import { Button, Card, Header } from "../components/UI";
import { Heading2 } from "../components/typography";

// Page specific components
import BranchCard from "../components/PageSpecificComponents/pagesComponents/manageBranches/branchCard/BranchCard";

// Builders
import * as buttonDesignType from "../builders/buttonDesign.types";

// Actions
import { deleteBranch } from "../redux/actions/app.actions";

// Calls
import {
  fetchBranchesRequest,
  removeBranchRequest
} from "../api/calls/branch.call";

// Styles
import { ManageBranchesStyles } from "../pagesStyles/manageBranches.styles";

const ManageBranches = () => {
  const [branches, setBranches] = useState([]);
  const { activeBranch } = useSelector(state => state.app);
  const dispatch = useDispatch();
  const history = useHistory();

  const getBranches = async () => {
    const response = await fetchBranchesRequest();
    if (response.hasError) {
      toast.error(response.error.message);
      return;
    }
    setBranches(response.branches);
  };

  useEffect(() => {
    getBranches();
  }, []);

  const deleteBranchHandler = async id => {
    const result = await swal({
      title: "Delete Branch?",
      text: "Are you sure you want to delete this branch? This action cannot be undone.",
      buttons: true,
      dangerMode: true
    });
    if (!result) {
      return;
    }
    const response = await removeBranchRequest(id);
    if (response.hasError) {
      toast.error(response.error.message);
      return;
    }
    setBranches(pervValues => pervValues.filter(branch => branch.id !== id));
    dispatch(deleteBranch(id));
    toast.success(response.message);
  };

  return (
    <ManageBranchesStyles>
      <PageHeader title="Manage Branches" />
      <Card className="headerCard">
        <Header className="header">
          <Heading2>YOUR BRANCHES</Heading2>
          <Button
            onClick={() => history.push("/create-branch")}
            buttonDesignType={buttonDesignType.PRIMARY}
          >
            + Add New Branch
          </Button>
        </Header>
      </Card>
      <div className="branchesList">
        {branches?.map(branch => {
          const branchId = branch.id;
          return (
            <BranchCard
              {...branch}
              isActive={branchId === activeBranch?.id}
              key={branchId}
              deleteBranch={() => deleteBranchHandler(branchId)}
            />
          );
        })}
      </div>
    </ManageBranchesStyles>
  );
};

export default ManageBranches;
