import { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { Picker } from "emoji-mart";
// SVGS
import { SmileIcon, MessageSendIcon, Bell } from "../../SVGS";
// hooks
import useClickOutside from "../../hooks/useClickOutside";

// Utils
import { isEmpty } from "../../utils";

// styles
import { ChatInputStyles } from "./chatInput.styles";
import "emoji-mart/css/emoji-mart.css";

const ChatInput = ({
  serviceId,
  isPresetOpen,
  setIsPresetsOpen,
  setNewPresetText,
  newPresetText
}) => {
  const { socket } = useSelector(state => state.app);
  const [input, setInput] = useState("");
  const [isEmojisOpen, setIsEmojisOpen] = useState(false);
  const picker = useRef(null);

  const sendMessage = async () => {
    if (!isEmpty(input) && !isEmpty(socket)) {
      const messageData = {
        service_id: `${serviceId}`,
        message_id: uuidv4(),
        body: input,
        type: isPresetOpen ? "system" : "text"
      };

      await socket.emit("message", messageData);
      setInput("");
    }
  };

  const addEmoji = emoji => {
    if ("native" in emoji) {
      setInput(`${input}${emoji.native}`);
      setIsEmojisOpen(false);
    }
  };

  const dismissPicker = () => {
    setIsEmojisOpen(false);
  };

  // resetsInput if presets Are on or off
  useEffect(() => {
    setInput("");
  }, [isPresetOpen]);

  // updates new preset text value in chat component with input value
  useEffect(() => {
    if (isPresetOpen) {
      setNewPresetText(input);
    }
  }, [input, setNewPresetText, isPresetOpen]);

  useEffect(() => {
    setInput(newPresetText);
  }, [newPresetText]);

  useClickOutside([picker], dismissPicker);

  return (
    <ChatInputStyles isPresetOpen={isPresetOpen}>
      <div
        className="avatar"
        onClick={() => {
          setIsPresetsOpen(prevValue => !prevValue);
          setNewPresetText("");
        }}
      >
        <Bell isActive={isPresetOpen} />
      </div>

      <div className="chatInputContainer">
        <input
          className="chatTextInput"
          id="chatTextInput"
          placeholder="Write messages down here…"
          value={input}
          onChange={e => setInput(e.target.value)}
          onKeyDown={e => {
            if (e.key === "Enter") {
              sendMessage();
            }
          }}
        />
        <div ref={picker}>
          {!isPresetOpen && (
            <div
              className="emojisContainer"
              onClick={() => setIsEmojisOpen(!isEmojisOpen)}
            >
              <SmileIcon />
            </div>
          )}
          {isEmojisOpen && (
            <Picker
              useButton={false}
              set="facebook"
              title=""
              emoji=""
              native={true}
              style={{ position: "absolute", bottom: 40, right: 0 }}
              onSelect={addEmoji}
            />
          )}
          <div className="messageContainer" onClick={sendMessage}>
            <MessageSendIcon />
          </div>
        </div>
      </div>
    </ChatInputStyles>
  );
};

export default ChatInput;
