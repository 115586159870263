import { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

// Components
import NavLink from "./NavLink/NavLink";
import { Heading2 } from "../typography";
import { Body, Card, CentralModal, Header } from "../UI";
import BranchItem from "../lists/branches/Branch.item";

// Icons
import {
  Close,
  Logo,
  BranchesArrow,
  ProfileIcon,
  LogoutIcon
} from "../../SVGS";

// Images
import Profile from "../../images/Profile.png";

// Actions
import { logout, setActiveBranch } from "../../redux/actions/app.actions";

// Configs
import { generateMeniLinks } from "../../config/navigation.config";

// Services
import { enableScroll, disableScroll } from "../../utils";

//Utils
import { isEmpty } from "../../utils/global.utils";

// Styles
import { SideMenuStyles } from "./sideMenu.styles";

const SideMenu = () => {
  const location = useLocation();
  const history = useHistory();
  const { user, activeBranch, branches, menuItems } = useSelector(
    state => state.app
  );
  const dispatch = useDispatch();

  const [isBranchesOpen, setIsBranchesOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);

  const selectedBranchExists = !isEmpty(branches) && !isEmpty(activeBranch);

  const toggleBranches = () => {
    setIsBranchesOpen(prevState => !prevState);
    if (isBranchesOpen) {
      enableScroll();
    } else {
      disableScroll();
    }
  };

  const closeBranches = () => {
    setIsBranchesOpen(false);
    enableScroll();
  };

  return (
    <SideMenuStyles>
      <div className="sideMenu">
        <Logo className="logo" />
        <div
          className="brachContainer"
          onClick={() => (!isEmpty(branches) ? toggleBranches() : {})}
        >
          <p className="selectBranch">
            {selectedBranchExists && "Selected Branch"}
          </p>
          <div className="chooseBracnhContainer">
            <p className="chosenBranch">
              {selectedBranchExists
                ? `${activeBranch?.name}`
                : "No Selected Branch"}
            </p>

            <div className={`arrowContainer ${isBranchesOpen && "arrowAnim"}`}>
              <BranchesArrow />
            </div>
          </div>
          <p className="address">
            {selectedBranchExists && `${activeBranch?.location?.address}`}
          </p>
        </div>
        <div className="linksContainer">
          {generateMeniLinks(menuItems).map((linkProperties, i) => (
            <NavLink
              {...linkProperties}
              isActive={location.pathname === linkProperties.path}
              key={i}
            />
          ))}
        </div>
        <div className="profile" onClick={() => setIsProfileOpen(true)}>
          <div className="imgContainer">
            <img src={Profile} alt="profile" />
          </div>
          <div className="profileSettings">
            <h5 className="userName">
              {`${user?.firstName || ""} ${user?.lastName || ""}`}
            </h5>
            <p className="accSettings">Account Settings</p>
          </div>
        </div>
      </div>
      {isBranchesOpen && (
        <div className="branchesContainer">
          <div>
            <Card className="branchesCard">
              <Header className="branchesHeader">
                <Heading2>YOUR BRANCHES</Heading2>
                <Close onClick={closeBranches} />
              </Header>
              <Body className="branchesBody">
                {branches.map(branch => (
                  <BranchItem
                    {...branch}
                    isActive={activeBranch?.id === branch.id}
                    key={branch.id}
                    onClick={() => {
                      dispatch(setActiveBranch(branch));
                      toggleBranches();
                    }}
                  />
                ))}
              </Body>
            </Card>
          </div>
        </div>
      )}
      {isProfileOpen && (
        <CentralModal closeModal={() => setIsProfileOpen(false)}>
          <Card>
            <Header className="profileHeader">
              <h3 className="name">{`${user?.firstName} ${user?.lastName}`}</h3>
              <p className="email">{user?.email}</p>
            </Header>
            <Body className="profileBody">
              <button
                onClick={() => history.push("/account-settings")}
                type="button"
                className="button"
              >
                <ProfileIcon />
                <p className="buttonText">Profile</p>
              </button>
              <button
                type="button"
                onClick={() => {
                  dispatch(logout());
                  toast.success("You have logged out successfully");
                }}
                className="button"
              >
                <LogoutIcon />
                <p className="buttonText">Logout</p>
              </button>
            </Body>
          </Card>
        </CentralModal>
      )}
    </SideMenuStyles>
  );
};

export default SideMenu;
