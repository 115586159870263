import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// components
import { PageHeader } from "../components/PageSpecificComponents";
import { Card, SideModal } from "../components/UI";
import TiketDetails from "../components/TiketDetails/TiketDetails";
import Chat from "../containers/chat/Chat";
import ListHeader from "../components/PageSpecificComponents/pagesComponents/dashboard/list-header/ListHeader";
import DashboardServiceItem from "../components/lists/dashboard/service-item";
// import NotificationItem from "../components/lists/dashboard/notification-item";
import VerifyBusiness from "../components/PageSpecificComponents/pagesComponents/dashboard/verify-business/VerifyBusiness";
import PendingVerification from "../components/PageSpecificComponents/pagesComponents/dashboard/pending-verification/PendingVerification";
import ChoosePlan from "../components/PageSpecificComponents/pagesComponents/dashboard/choose-plan/ChosePlan";
import Plan from "../components/PageSpecificComponents/pagesComponents/dashboard/plan/Plan";

// Images
import Profile from "../images/Profile.png";

// Domain Model
// import { Service } from "../models/app/domain";

// Calls
import { fetchDashboardRequest } from "../api/calls/dashboard.call";
import { fetchServiceDetailsRequest } from "../api/calls/service-requests.call";

// Constants
import * as modalTypes from "../constants/serviceRequests.constants";

// Actions
// import { seeNewMessages } from "../redux/actions/services.actions";
import { setNewChatMessage } from "../redux/actions/chat.actions";

// Utils
import { enableScroll, disableScroll, isEmpty } from "../utils";
import { convertTimeStampToDate } from "../utils/timeStampToDate";

// styles
import { DashboardStyles } from "../pagesStyles/dashboard.styles";

const Dashboard = () => {
  const { activeBranch, user, dashboardData } = useSelector(state => state.app);
  const { newMessage } = useSelector(state => state.chatReducer);
  const [dashboardState, setDashboardState] = useState({
    services: [],
    totalServices: 0,
    requests: [],
    totalRequests: 0,
    notifications: [],
    totalNotifications: 0,
    modalType: undefined,
    selectedService: undefined
  });
  const [isChatOpen, setIsChatOpen] = useState(false);
  const dispatch = useDispatch();

  const getDashboardData = async () => {
    const response = await fetchDashboardRequest();
    if (response.hasError) {
      return;
    }

    setDashboardState(prevState => ({
      ...prevState,
      services: response.services.items,
      totalServices: response.services.total,
      requests: response.requests.items,
      totalRequests: response.requests.total,
      notifications: response.notifications.items,
      totalNotifications: response.notifications.total
    }));
  };

  const closeModal = () => {
    setDashboardState(prevState => ({
      ...prevState,
      modalType: undefined,
      selectedService: undefined
    }));
    getDashboardData();
  };

  const serviceDetails = async serviceId => {
    const response = await fetchServiceDetailsRequest(serviceId);
    if (response.hasError) {
      return;
    }
    setDashboardState(prevState => ({
      ...prevState,
      modalType: modalTypes.TIKET_DETAILS,
      selectedService: response.service
    }));
    disableScroll();
  };

  useEffect(() => {
    getDashboardData();
  }, [activeBranch]);

  useEffect(() => {
    if (newMessage && !isChatOpen) {
      setDashboardState(prevState => ({
        ...prevState,
        selectedService: {
          ...prevState.selectedService,
          newMessagesCount: prevState.selectedService.newMessagesCount + 1
        }
      }));
      dispatch(setNewChatMessage(undefined));
    }
  }, [newMessage, isChatOpen, dispatch]);

  const toggleChat = () => {
    setIsChatOpen(prevValue => !prevValue);
    getDashboardData();
    setDashboardState(prevState => ({
      ...prevState,
      selectedService: { ...prevState.selectedService, newMessagesCount: 0 }
    }));
  };

  // const openNotification = async serviceId => {
  //   const response = await fetchServiceDetailsRequest(serviceId);
  //   if (response.hasError) {
  //     return;
  //   }
  //   setDashboardState(prevState => ({
  //     ...prevState,
  //     modalType: modalTypes.TIKET_DETAILS,
  //     selectedService: response.service
  //   }));
  //   setIsChatOpen(prevValue => !prevValue);
  // };

  const generateDay = () => {
    const day = new Date().getDay();
    switch (day) {
      case 1:
        return "Monday";
      case 2:
        return "Tuesday";
      case 3:
        return "Wednesday";
      case 4:
        return "Thursday";
      case 5:
        return "Friday";
      case 6:
        return "Saturday";
      case 0:
        return "Sunday";
      default:
        return "Unknown";
    }
  };

  return (
    <DashboardStyles>
      <PageHeader title="Dashboard" />
      <div className="userContainer">
        <div className="user">
          <div className="userDetails">
            <div className="avatar">
              <img src={Profile} alt="profile" className="profileImage" />
            </div>
            <div className="userDetailsContainer">
              <h2 className="userName">
                Welcome {user?.firstName} {user?.lastName}!
              </h2>
              <p className="position">{user?.roles[0]?.name}</p>
            </div>
          </div>
          <div className="dateContainer">
            <p className="date">
              {convertTimeStampToDate(new Date().getTime() / 1000)}
            </p>
            <p className="day">{generateDay()}</p>
          </div>
        </div>
        {dashboardData?.verificationStatus && (
          <div className="chip">
            {dashboardData?.verificationStatus === "not_verfied" && (
              <VerifyBusiness />
            )}
            {dashboardData?.verificationStatus === "pending" && (
              <PendingVerification />
            )}
            {dashboardData?.verificationStatus === "verified" &&
              isEmpty(dashboardData?.subscriptionPlanName) && <ChoosePlan />}
            {dashboardData?.verificationStatus === "verified" &&
              !isEmpty(dashboardData?.subscriptionPlanName) && (
                <Plan
                  subscriptionPlanName={dashboardData.subscriptionPlanName}
                  subscriptionPlanEndPeriod={
                    dashboardData.currentSubscription.endPeriod
                  }
                />
              )}
          </div>
        )}
      </div>
      <div className="cardsContainer">
        <Card className="card">
          <ListHeader
            number={dashboardState.totalRequests}
            title="New Service Requests"
            numberColor="#0093EC"
            link="/service-requests"
          />
          {!isEmpty(dashboardState.requests) && (
            <>
              <div className="servicesList">
                {dashboardState.requests.map(request => (
                  <DashboardServiceItem
                    {...request}
                    bgColor={"#EEF7FC"}
                    key={request.id}
                    onClick={() => serviceDetails(request.id)}
                  />
                ))}
              </div>
              {dashboardState.totalRequests > 4 && (
                <p className="hasMore">
                  + {dashboardState.totalRequests - 4} more
                </p>
              )}
            </>
          )}
        </Card>
        <Card className="card">
          <ListHeader
            number={dashboardState.totalServices}
            title="Services for today"
            numberColor="#03CA82"
            link="/services"
          />
          {!isEmpty(dashboardState.services) && (
            <>
              <div className="servicesList">
                {dashboardState.services.map(service => (
                  <DashboardServiceItem
                    {...service}
                    bgColor={"#EEF7FC"}
                    key={service.id}
                    onClick={() => serviceDetails(service.id)}
                  />
                ))}
              </div>
              {dashboardState.totalServices > 4 && (
                <p className="hasMore">
                  + {dashboardState.totalServices - 4} more
                </p>
              )}
            </>
          )}
        </Card>
        {/* <Card className="card">
          <ListHeader
            number={dashboardState.totalNotifications}
            title="New notifications"
            numberColor="#FF4747"
          />
          {!isEmpty(dashboardState.totalNotifications) && (
            <>
              <div className="servicesList">
                {dashboardState.notifications.map(notification => (
                  <DashboardServiceItem
                    {...notification}
                    bgColor={"#EEF7FC"}
                    key={notification.id}
                    onClick={() => openNotification(notification.id)}
                    isNotification
                  />
                ))}
              </div>
              {dashboardState.totalNotifications > 4 && (
                <p className="hasMore">
                  + {dashboardState.totalNotifications - 4} more
                </p>
              )}
            </>
          )}
        </Card> */}
        {dashboardState.modalType === modalTypes.TIKET_DETAILS && (
          <SideModal
            closeModal={() => {
              enableScroll();
              closeModal();
              setIsChatOpen(false);
            }}
          >
            <TiketDetails
              serviceDetails={dashboardState.selectedService}
              // tiketDetailsModalType={tiketDetailsModalType}
              toggleChat={() => toggleChat(dashboardState.selectedService.id)}
              closeModal={() => {
                enableScroll();
                closeModal();
                setIsChatOpen(false);
              }}
            />
            {isChatOpen && <Chat service={dashboardState.selectedService} />}
          </SideModal>
        )}
      </div>
    </DashboardStyles>
  );
};

export default Dashboard;
