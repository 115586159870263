import styled from "styled-components";

export const TiketDetailsStyles = styled.div`
  .tiketDetailsCard {
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow: -6px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 2;
    position: relative;

    .topHeader {
      padding: 20px 24px 12px 24px;

      .headerTopContent {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;

        .titleAndPill {
          display: flex;
          align-items: center;

          .pill {
            margin-left: 12px;
          }
        }
      }
    }
  }

  .messagesButton {
    width: 100%;
    border-radius: 6px;
    padding: 8.5px 0;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    .notificationCounter {
      margin-left: 10px;
      width: 16px;
      height: 16px;
    }
  }

  .tiketDetailsBody {
    padding: 12px 24px 0 24px;
    border-bottom: 1px solid #e1e3e7;
  }

  .cardContainer {
    margin-bottom: 12px;

    .cardItem {
      border: 1px solid #dfe1e6;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 4px 8px;
      margin-top: 2px;

      .text {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #181818;
      }

      .boldText {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #181818;
      }

      .estimateButton {
        padding: 8px 16px;
        border-radius: 6px;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
      }
    }

    .mapContainer {
      height: 150px;
      background: gray;
      position: relative;
      margin-top: 2px;

      .copyLocation {
        position: absolute;
        top: 8px;
        right: 8px;
        padding: 8px 16px;
        background: #fafbfc;
        border: 1px solid #dfe1e6;
        border-radius: 6px;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        color: #292359;
        z-index: 2;
      }
    }
  }

  .editWorkersButton {
    width: 100%;
    padding: 8px 16px;
    background: #fafbfc;
    border: 1px solid #dfe1e6;
    border-radius: 6px;
    margin-top: 2px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #292359;
  }

  .workersList {
    margin: 12px 0;
  }
`;
