import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

// Components
import { PageHeader } from "../components/PageSpecificComponents";
import {
  Card,
  Header,
  DatePicker,
  NotificationsCounter,
  Body,
  SideModal,
  CentralModal
} from "../components/UI";
import { Heading2, Label, Paragraph } from "../components/typography";
import TiketDetails from "../components/TiketDetails/TiketDetails";
import Row from "../components/lists/Row";
import ServiceItem from "../components/lists/services/Service.item";

// Containers
import WorkersForm from "../containers/forms/workers/Workers.form";
import Chat from "../containers/chat/Chat";

// Builders
import { filters, tableHeadBuilder } from "../builders/services.builder";

// Icons
import { SearchIcon } from "../SVGS";

// Constants
import * as modalTypes from "../constants/serviceRequests.constants";
import * as filtersConstats from "../constants/services.constants";

// Actions
import {
  getServices,
  getMoreServices,
  openTicketDetails,
  closeModal,
  closeCentralModal,
  seeNewMessages,
  setNewMessageToService
} from "../redux/actions/services.actions";
import { setNewChatMessage } from "../redux/actions/chat.actions";

// Services
import { disableScroll, enableScroll, isEmpty } from "../utils";

// Styles
import { ServicesStyles } from "../pagesStyles/services.styles";

const Services = () => {
  const dispatch = useDispatch();
  const {
    services,
    lastService,
    hasMore,
    selectedService,
    modalType,
    centralModalType,
    shouldRefetchServicesAfterAction,
    workersFormData,
    tiketDetailsModalType
  } = useSelector(state => state.servicesReducer);
  const { activeBranch } = useSelector(state => state.app);
  const { newMessage } = useSelector(state => state.chatReducer);

  const [isChatOpen, setIsChatOpen] = useState(false);

  // Query states
  const [chosenFilter, setChosenFilter] = useState(filters[0].value);
  const [idQuery, setIdQuery] = useState("");
  const [dateQuery, setDateQuery] = useState(new Date());

  const handleDateQueryChange = (name, value) => {
    setDateQuery(value);
  };

  const convertDateToString = date => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = `${date.getFullYear()}`;

    const formatedDay = day < 10 ? `0${day}` : `${day}`;
    const formatedMonth = month < 10 ? `0${month}` : `${month}`;

    return `${year}-${formatedMonth}-${formatedDay}`;
  };

  useEffect(() => {
    if (newMessage && !isChatOpen) {
      dispatch(setNewMessageToService(parseInt(newMessage.serviceId, 0)));
      dispatch(setNewChatMessage(undefined));
    }
  }, [newMessage, isChatOpen, dispatch]);

  useEffect(() => {
    dispatch(
      getServices({
        dateQuery: convertDateToString(dateQuery),
        groupQuery: chosenFilter,
        idQuery,
        limit: 10
      })
    );
  }, [activeBranch, chosenFilter, idQuery, dateQuery, dispatch]);

  useEffect(() => {
    if (shouldRefetchServicesAfterAction === "refetch") {
      dispatch(
        getServices({
          dateQuery: convertDateToString(dateQuery),
          groupQuery: chosenFilter,
          idQuery,
          limit: 10
        })
      );
    }
  }, [
    dateQuery,
    chosenFilter,
    idQuery,
    shouldRefetchServicesAfterAction,
    services,
    dispatch
  ]);

  const fetchMoreServices = () => {
    dispatch(
      getMoreServices({
        dateQuery: convertDateToString(dateQuery),
        groupQuery: chosenFilter,
        idQuery,
        limit: 10,
        lastService: lastService
      })
    );
  };

  const toggleChat = serviceId => {
    dispatch(setNewChatMessage(undefined));
    dispatch(seeNewMessages(serviceId));
    setIsChatOpen(prevValue => !prevValue);
  };

  const notificationsCount = () => {
    let notificationsCount = 0;
    services.forEach(service => {
      notificationsCount = notificationsCount + service.newMessagesCount;
    });
    return notificationsCount;
  };

  return (
    <ServicesStyles>
      <PageHeader title="Services" />
      <Card className="card">
        <Header>
          <Heading2>SERVICES</Heading2>
        </Header>
        <Header className="filters">
          <div style={{ marginRight: 24 }}>
            <Label>Search by ID</Label>
            <div className="searchContainer">
              <SearchIcon className="icon" />
              <p className="para">ID:</p>
              <input
                className="searchInput"
                value={idQuery}
                onChange={e => setIdQuery(e.target.value)}
              />
            </div>
          </div>
          <div>
            <Label>Date from</Label>
            <div>
              <DatePicker
                style={{ marginTop: 2, width: 250 }}
                value={dateQuery}
                onChange={handleDateQueryChange}
                shouldHaveError={false}
                dateFormat="MM/dd/yyyy"
                disabled={!isEmpty(idQuery)}
              />
            </div>
          </div>
        </Header>
        <Header className="statusFilters">
          <div className="statusFiltersContainer">
            {filters.map(({ value, title }, i) => (
              <button
                key={i}
                type="button"
                onClick={() => setChosenFilter(value)}
                className={`tab ${chosenFilter === value && "activeTab"}`}
              >
                {title}
              </button>
            ))}
          </div>
          <div className="statusFiltersContainer">
            <button
              type="button"
              className={`tab ${
                chosenFilter === filtersConstats.FILTERS_NOTIFICATION &&
                "activeTab"
              }`}
              onClick={() =>
                setChosenFilter(filtersConstats.FILTERS_NOTIFICATION)
              }
              style={{ cursor: "pointer", marginRight: 12 }}
            >
              Notifications:
            </button>
            <NotificationsCounter counter={notificationsCount()} />
          </div>
        </Header>
        <Body className="tableHead">
          {tableHeadBuilder.map(({ title, className }, i) => (
            <Paragraph key={i} className={`headTitle ${className}`}>
              {title}
            </Paragraph>
          ))}
        </Body>
      </Card>
      <Card className="list">
        <Body style={{ padding: 0 }}>
          <InfiniteScroll
            dataLength={services.length}
            next={fetchMoreServices}
            hasMore={hasMore}
          >
            {services.map(service => (
              <Row
                onClick={() => {
                  disableScroll();
                  dispatch(openTicketDetails(service.id));
                }}
                className="listRow"
                key={service.id}
              >
                <ServiceItem {...service} />
              </Row>
            ))}
          </InfiniteScroll>
        </Body>
      </Card>

      {modalType === modalTypes.TIKET_DETAILS && (
        <SideModal
          closeModal={() => {
            enableScroll();
            dispatch(closeModal());
            setIsChatOpen(false);
          }}
        >
          <TiketDetails
            serviceDetails={selectedService}
            tiketDetailsModalType={tiketDetailsModalType}
            shouldShowWokers
            toggleChat={() => toggleChat(selectedService.id)}
            closeModal={() => {
              enableScroll();
              dispatch(closeModal());
              setIsChatOpen(false);
            }}
          />
          {isChatOpen && <Chat service={selectedService} />}
        </SideModal>
      )}

      {centralModalType === modalTypes.WORKERS && (
        <CentralModal
          className="modalCard"
          closeModal={() => {
            enableScroll();
            dispatch(closeCentralModal());
          }}
        >
          <Card>
            <WorkersForm
              closeModal={() => {
                enableScroll();
                dispatch(closeCentralModal());
              }}
              workersFormData={workersFormData}
            />
          </Card>
        </CentralModal>
      )}
    </ServicesStyles>
  );
};

export default Services;
