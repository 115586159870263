import { all, call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import io from "socket.io-client";
import { getItem } from "../../utils";
import { TOKEN } from "../../constants/localStorage/localStorage.constants";
// Action types
import * as types from "../actionTypes/app.types";

// Custom history
import history from "../../history";

// Calls
import {
  fetchAuthenticatedUserRequest,
  loginUserRequest,
  registerUserRequest,
  updateProfileRequest
  // fetchChatToken
} from "../../api/calls/app.call";
import { fetchSystemComponents } from "../../api/calls/system-components.call";
import {
  createBranchRequest,
  fetchBranchesRequest,
  updateBranchRequest
} from "../../api/calls/branch.call";

// Actions
import {
  fetchAuthenticatedUserDataSuccess,
  fetchSystemComponentsSuccess,
  registerSuccess,
  updateProfileSuccess,
  loginSuccess,
  createBranchSuccess,
  fetchBranchesSuccess,
  updateBranchSuccess,
  connectToSocketSuccess
} from "../actions/app.actions";

// Fetch authenticated user details logic

function* getAuthenticatedUserData() {
  const response = yield call(fetchAuthenticatedUserRequest);
  if (response.hasError) {
    return yield put({
      type: types.FETCH_AUTHENTICATED_USER_FAILED
    });
  }
  yield put(fetchAuthenticatedUserDataSuccess(response));
}

function* getAuthenticatedUserDataWatcher() {
  yield takeLatest(
    types.FETCH_AUTHENTICATED_USER_REQUEST,
    getAuthenticatedUserData
  );
}

// Login user logic

function* login(action) {
  const response = yield call(loginUserRequest, action.loginFormValues);
  if (response.hasError) {
    toast.error(response.error.message);
    return yield put({
      type: types.LOGIN_FAILED
    });
  }
  yield put(loginSuccess(response));
}

function* loginSagaWatcher() {
  yield takeLatest(types.LOGIN_REQUEST, login);
}

// Register user logic

function* register(action) {
  const response = yield call(registerUserRequest, action.registerFormValues);
  if (response.hasError) {
    toast.error(response.error.message);
    return yield put({
      type: types.REGISTER_FAILED
    });
  }
  yield put(registerSuccess(response));
  history.replace("/");
}

function* registerSagaWatcher() {
  yield takeLatest(types.REGISTER_REQUEST, register);
}

// Update user profile logic

function* updateProfile(action) {
  const response = yield call(
    updateProfileRequest,
    action.profileFormValues,
    action.initialProfileData
  );
  if (response.hasError) {
    toast.error(response.error.message);
    return yield put({
      type: types.UPDATE_PROFILE_FAILED
    });
  }
  yield put(updateProfileSuccess(response));
}

function* updateProfileSagaWatcher() {
  yield takeLatest(types.UPDATE_PROFILE_REQUEST, updateProfile);
}

// Fetch system components logic

function* fetchSystemComponentsRequest(action) {
  const response = yield call(fetchSystemComponents, action.queryParams);
  if (response.hasError) {
    toast.error(response.error.message);
    return yield put({
      type: types.FETCH_SYSTEM_COMPONENTS_FAILED
    });
  }

  yield put(fetchSystemComponentsSuccess(response));
}

function* fetchSystemComponentsSagaWatcher() {
  yield takeLatest(types.FETCH_SYSTEM_COMPONENTS, fetchSystemComponentsRequest);
}

// Create branch  logic

function* createBranch(action) {
  const response = yield call(createBranchRequest, action.branchFormValues);
  if (response.hasError) {
    toast.error(response.error.message);
    return yield put({
      type: types.CREATE_BRANCH_FAILED
    });
  }
  yield put(createBranchSuccess(response.branch));
}

function* createBranchSagaWatcher() {
  yield takeLatest(types.CREATE_BRANCH_REQUEST, createBranch);
}

// Update branch  logic

function* updateBranch(action) {
  const response = yield call(
    updateBranchRequest,
    action.branchFormValues,
    action.initialValues,
    action.id
  );
  if (response.hasError) {
    toast.error(response.error.message);
    return yield put({
      type: types.UPDATE_BRANCH_FAILED
    });
  }
  yield put(updateBranchSuccess(response.branch));
}

function* updateBranchSagaWatcher() {
  yield takeLatest(types.UPDATE_BRANCH_REQUEST, updateBranch);
}

// Fetch branches  logic

function* fetchBranches() {
  const response = yield call(fetchBranchesRequest);
  if (response.hasError) {
    toast.error(response.error.message);
    return yield put({
      type: types.FETCH_BRANCHES_FAILED
    });
  }
  yield put(fetchBranchesSuccess(response.branches));
}

function* fetchBranchesSagaWatcher() {
  yield takeLatest(types.FETCH_BRANCHES_REQUEST, fetchBranches);
}

// connect to socket logic
function* connectToSocketCall() {
  const socket = io.connect("https://dev.socket.truckfix-chat.bstorm.app", {
    extraHeaders: {
      Authorization: `Bearer ${getItem(TOKEN)}`
    }
  });

  yield put(connectToSocketSuccess(socket));
}

function* connectToSocketSagaWatcher() {
  yield takeLatest(types.FETCH_CHAT_AUTH_TOKEN_REQUEST, connectToSocketCall);
}

export default function* appSaga() {
  yield all([
    getAuthenticatedUserDataWatcher(),
    loginSagaWatcher(),
    registerSagaWatcher(),
    updateProfileSagaWatcher(),
    fetchSystemComponentsSagaWatcher(),
    createBranchSagaWatcher(),
    fetchBranchesSagaWatcher(),
    updateBranchSagaWatcher(),
    connectToSocketSagaWatcher()
  ]);
}
