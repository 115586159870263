import styled from "styled-components";

export const ManageBranchesStyles = styled.section`
  min-height: calc(100vh - 42px);

  .headerCard {
    margin-top: 24px;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .branchesList {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;
  }
`;
